@import "../../styles/normalize.scss";
@import "../../styles/common.scss";

body {
    background-color:white;
    font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Nimbus Sans L", Arial, "Liberation Sans", "Hiragino Sans GB", "Source Han Sans CN Normal", "Microsoft YaHei", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", SimHei, "WenQuanYi Zen Hei Sharp", sans-serif;
}
.zone-title{
    line-height: 100px;
    font-size: 32px;
}



.customer-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.customer-logo-size{
    width: 2.17rem;
    height: 0.97rem;
    margin-right: 0.12rem;
}