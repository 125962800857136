@import "../../styles/normalize.scss";
@import "../../styles/common.scss";

body {
    background-color:white;
    font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Nimbus Sans L", Arial, "Liberation Sans", "Hiragino Sans GB", "Source Han Sans CN Normal", "Microsoft YaHei", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", SimHei, "WenQuanYi Zen Hei Sharp", sans-serif;
}
.zone-title{
    line-height: 100px;
    font-size: 32px;
}



.footer-zone-wrap{
    background-color: #0065F8;
    color: white;
    font-size: 0.18rem;
    padding-top: 0.8rem;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
}
.footer-left{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 0.24rem;

    .footer-contact-us{
        font-size: 0.3rem;
        margin-bottom: 0.21rem;        
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
    }
    p{
        margin-bottom: 0.08rem;
    }
}
.footer-right-item{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.footer-right{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.24rem;
}
.qrcode-text{
    margin-top: 0.28rem;
}
.footer-copyright{
    line-height: 1.2rem;
    text-align: center;
}

.affix-wrap{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.affix-box-1{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 1.9rem;
    height: 3.58rem;
    background-color: white;
    box-shadow: 0px 2px 12px -1px rgba(223, 223, 223, 0.5);
    border-radius: 8px;
    padding: 0.16rem;
}
.affix-box-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1.58rem;
    height: 2.63rem;
}
.affix-scan-text{
    font-size: 0.18rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1D2341;
}
.affix-btn{
    width: 1.58rem;
    height: 0.46rem;
    line-height: 0.46rem;
    background: #0065F8;
    border-radius: 0.08rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.24rem;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    margin-top: 0.19rem;
}
