@import "../../styles/normalize.scss";
@import "../../styles/common.scss";

body {
    background-color:white;
    font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Nimbus Sans L", Arial, "Liberation Sans", "Hiragino Sans GB", "Source Han Sans CN Normal", "Microsoft YaHei", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", SimHei, "WenQuanYi Zen Hei Sharp", sans-serif;
}
.zone-title{
    line-height: 100px;
    font-size: 32px;
}




.introduction-wrap{
    padding: 0 1rem 0 1rem;
    text-align: center;
    background-color: #FAF9F9;
    font-size: 0.24rem;
    color: #101736;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
}
.introduction-detail{
    padding: 0.39rem;
    border: 0.02rem solid #0065F8;
    margin: 0.55rem;
    p{
        text-align: left;
        letter-spacing: 0.02rem;
        line-height: 0.3rem;
    }
}