@import "../../styles/normalize.scss";
@import "../../styles/common.scss";

body {
    background-color:white;
    font-family: "PingFang SC", "Helvetica Neue", Helvetica, "Nimbus Sans L", Arial, "Liberation Sans", "Hiragino Sans GB", "Source Han Sans CN Normal", "Microsoft YaHei", "Wenquanyi Micro Hei", "WenQuanYi Zen Hei", "ST Heiti", SimHei, "WenQuanYi Zen Hei Sharp", sans-serif;
}
.zone-title{
    line-height: 100px;
    font-size: 32px;
}



.requirement-wrap{
    padding: 0 1rem 0 1rem;
    background-color: #FAF9F9;
    font-size: 0.24rem;
    color: #101736;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
}

.requirement-title{
    height: 0.3rem;
    font-size: 0.22rem;
    color: #6A7196;
    line-height: 0.3rem;
    text-align: center;
}
.requirement-area-title{
    margin-top: 0.17rem;
    height: 0.33rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333D68;
    line-height: 0.33rem;
    margin-bottom: 0.12rem;
}
.requirement-description{
    border-radius: 0.08rem;    
    border: 0.01rem solid #EEEFF6;
    font-size: 0.22rem;
    line-height: 0.3rem;
    padding: 0.2rem;
}


.requirement-item-title{
    font-size: 0.24rem;
    font-family: PingFangSC-Semibold;
    font-weight: 600;
    color: #333D68;
    line-height: 0.33rem;
}
.requirement-item-input{
    height: 0.49rem;
    background: #FFFFFF;
    border-radius: 0.08rem;
    border: 0.01rem solid #EEEFF6;
    padding-left: 0.16rem;
}
.ant-input{
    font-size: 0.22rem;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #6A7196;
}
.ant-form-item-label {
    text-align: left;
}
.ant-form-item-label > label {
    height: 0.33rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333D68;
    line-height: 0.33rem;

}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: ''; 
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    content: ''; 
}

.requirement-item-right{
    display: flex;
    align-items: center;
    justify-content: center;
    .requirement-item-right-input{
        width: 90px;
        margin-right: 5px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #EEEFF6;
        padding-left: 16px;
    }
}

.requirement-agreement{
    margin-top: 0.1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.22rem;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
}
.requirement-agreement-agree-text{
    margin-left: 8px;
    color: #101736;
    font-size: 0.22rem;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
}
.requirement-agreement-detail{
    color: #EE4E3B;
    font-size: 0.22rem;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
}



.requirement-btn-submit{
    width: 100%;
    height: 0.5rem;
    background: #0065F8;
    border-radius: 0.08rem;
    text-align: center;
    font-size: 0.24rem;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #FFFFFF;
    margin-bottom: 0.39rem;
}
